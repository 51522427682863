import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from '../styles/theme/theme';
import '../styles/all.scss';
import GlobalStyle from '../styles/GlobalStyle';

import Footer from './Footer';
import Navbar from './Navbar';

const TemplateWrapper = ({ children, page, background }) => {
	return (
		<ThemeProvider theme={theme}>
			<>
				<GlobalStyle />
				{page !== 'project' && <Navbar background={background} />}
				<main role="main">{children}</main>
				{page !== 'index' && page !== 'project' ? <Footer /> : undefined}
			</>
		</ThemeProvider>
	);
};

export default TemplateWrapper;
