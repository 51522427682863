module.exports = {
	white: '#ffffff',
	whitestWhite: '#F8F7F5',
	cloudDancer: '#EFEEEA',
	featherGrey: '#B6B09C',
	theBlarneyStone: '#AAA08A',
	denaliGreen: '#7E765D',
	astrogranite: '#747678',
	machineGunMetal: '#454545',
	balticSea: '#3B3D40',
	blackBeauty: '#24272A',
};

// Might come in handy: https://colornamer.netlify.com/
