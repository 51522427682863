import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import logo from '../img/pnr-footer-logo.svg';

const StyledFooter = styled.footer`
	background-color: ${(props) => props.theme.colors.lightest};

	.footer-top {
		padding-top: 15rem;
		padding-bottom: 8rem;

		small {
			font-size: 1.4rem;
		}

		a {
			color: ${(props) => props.theme.colors.secondary};
			transition: 300ms;

			&:hover {
				color: ${(props) => props.theme.colors.primary};
				text-decoration: underline;
			}
		}

		@media (max-width: 768px) {
			padding-top: 10rem;
			padding-bottom: 5rem;
		}
	}

	.footer-bottom {
		height: 3.2rem;
		background-color: ${(props) => props.theme.colors.primary};
	}
`;

const Footer = class extends React.Component {
	render() {
		return (
			<StyledFooter>
				<div className="has-text-centered footer-top">
					<Link to="/">
						<img
							src={logo}
							alt="Peaks & Rafters"
							style={{
								width: '31.5rem',
								maxWidth: '90%',
								height: 'auto',
								marginBottom: '1.5rem',
							}}
						/>
					</Link>
					<p>
						<small>
							&copy; {new Date().getFullYear()}{' '}
							<Link to="/toronto/">Toronto</Link> |{' '}
							<Link to="/port-carling/">Port Carling</Link>
						</small>
					</p>
				</div>
				<div className="footer-bottom"></div>
			</StyledFooter>
		);
	}
};

export default Footer;
