import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SocialIcons from '../components/social/SocialIcons';

import lightLogo from '../img/pnr-header-logo-light.svg';
import darkLogo from '../img/pnr-header-logo-dark.svg';
import close from '../img/close.svg';

const StyledNav = styled.header`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 3;
	width: 100%;

	.header-bar {
    position: absolute;
    height: 15rem;
		display: flex;
		flex-direction: row;
		align-items: center;
    width: 100%;
    z-index: 5;

		> .container {
			display: flex;
			align-items: flex-start;
			justify-content: ${(props) =>
				!!props.background ? 'space-between' : 'flex-end'};

			.logo-container {
				opacity: ${(props) => (props.active ? '0.2' : '1')};

				img {
					@media (min-width: 2500px) {
						width: 7vw;
					}
				}
			}
		}

		@media (max-width: 1023px) {
			padding: 0 1.5rem;
		}

		@media (min-width: 1024px) {
			height: 20rem;
		}
	}


	button {
		background: none;
		border: none;
		text-transform: uppercase;
		font-size: 1.6rem;
		letter-spacing: 0.2em;
		cursor: pointer;

		&.open-button {
			position: absolute;
			right: 0;
			color: ${(props) =>
				props.background === 'light'
					? props.theme.colors.secondary
					: props.theme.colors.textWhite};
			opacity: ${(props) => (props.active ? '0' : '1')};			

			&:focus {
				outline: none;
			}
		}

		&.close-button {
			position: absolute;
			right: 0;
			opacity: ${(props) => (props.active ? '1' : '0')};

			&::after {
				content: '';
				display: inline-block;
				background: center no-repeat url('${close}');
				background-size: cover;
				width: 16px;
				height: 16px;
			}
		}
	}

	.nav-container {
		${(props) =>
			!!props.active
				? 'background-color: rgba(36, 39, 42, 0.55); position: fixed; top: 0; right: 0; bottom: 0; left: 0; height: 100%; width: 100%; z-index: 4;'
				: undefined}		
		transition: 500ms;

		nav {
			display: flex;
			flex-direction: column;
			justify-content: center;
			position: fixed;
			height: 100vh;
			width: 36rem;
			max-width: 70%;
			padding: 3rem 4rem;
			right: -100%;
			/* z-index: 4; */
			transition: 1000ms ease-in-out;
			background-color: ${(props) => props.theme.colors.primary};

			&.active {
				right: 0;
			}

			ul {
				text-align: right;

				li {
					text-transform: uppercase;
					margin-bottom: 2rem;

					a {
						color: ${(props) => props.theme.colors.lightest};
						transition: 300ms;
						font-size: 1.8rem;
						letter-spacing: 2px;

						&:hover {
							color: ${(props) => props.theme.colors.secondary};
						}
					}
				}
			}

			[class^="SocialIcons"] {
				position: absolute;
				right: 4rem;
				bottom: 5rem;
			}
		}
	}
`;

const Navbar = ({ background }) => {
	const [isActive, setIsActive] = useState(false);
	const [backgroundColor, setBackgroundColor] = useState(undefined);

	useEffect(() => {
		if (!!background) {
			setBackgroundColor(background);
		}
	}, [background]);

	const pnrLogo = () => {
		if (backgroundColor === 'light') {
			return (
				<div className="logo-container">
					<Link to="/">
						<img src={darkLogo} alt="Peaks & Rafters" />
					</Link>
				</div>
			);
		} else if (backgroundColor === 'dark') {
			return (
				<div className="logo-container">
					<Link to="/">
						<img src={lightLogo} alt="Peaks & Rafters" />
					</Link>
				</div>
			);
		} else {
			return undefined;
		}
	};

	return (
		<StyledNav active={isActive} background={background} className="container">
			<div className="header-bar">
				<div className="container">
					{pnrLogo()}
					<button
						className="open-button"
						onClick={() => setIsActive(!isActive)}
					>
						<span className="visually-hidden">Open</span> Menu
					</button>
					<button
						className="close-button"
						onClick={() => setIsActive(!isActive)}
					>
						<span className="visually-hidden">Close Menu</span>
					</button>
				</div>
			</div>
			<div className="nav-container">
				<nav className={!!isActive ? `active` : undefined}>
					<ul>
						<li>
							<Link to="/our-work">Our Work</Link>
						</li>
						<li>
							<Link to="/about-us">About Us</Link>
						</li>
						<li>
							<Link to="/press">Press</Link>
						</li>
						<li>
							<Link to="/contact-us">Contact Us</Link>
						</li>
					</ul>
					<SocialIcons />
				</nav>
			</div>
		</StyledNav>
	);
};

export default Navbar;
