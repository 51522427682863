import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 10px;

    @media (min-width: 2500px) {
      font-size: 16px;
    }
  }

  body {
    font-family: ${(props) => props.theme.fonts.sans};
    font-size: 1.6rem;
    font-weight: ${(props) => props.theme.fonts.weights.light};
    color: ${(props) => props.theme.colors.secondary};
    background-color: ${(props) => props.theme.colors.lightest};
    overflow: hidden;
  }
`;

export default GlobalStyle;
