import React from 'react';
import styled from 'styled-components';

import Instagram from './Instagram';

const StyledSocialIcons = styled.ul`
	li {
		display: inline-block;
		height: 3rem;

		svg {
			height: 100%;
			width: 3rem;

			path,
			circle {
				transition: 300ms;
			}
		}

		+ li {
			margin-left: 1.5rem;
		}

		&:hover {
			svg {
				path,
				circle {
					fill: ${(props) => props.theme.colors.secondary};
				}
			}
		}
	}
`;

const SocialIcons = () => {
	return (
		<StyledSocialIcons>
			<li>
				<a
					href="https://instagram.com/peaksandrafters"
					target="_blank"
					rel="noopener noreferrer"
					title="Peaks and Rafters on Instagram"
				>
					<Instagram />
				</a>
			</li>
		</StyledSocialIcons>
	);
};

export default SocialIcons;
