const colors = require('./colors');
const fonts = require('./fonts');

module.exports = {
	colors: {
		primary: colors.featherGrey,
		secondary: colors.machineGunMetal,
		overlay: colors.blackBeauty,
		textWhite: colors.whitestWhite,
		textDarker: colors.blackBeauty,
		inputBg: colors.cloudDancer,
		inputText: colors.astrogranite,
		lightest: colors.white,
		darkest: colors.blackBeauty,
	},
	fonts: {
		sans: fonts.objektiv,
		serif: fonts.garamond,
		weights: {
			...fonts.weights,
		},
	},
};
